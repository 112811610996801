import { Typography } from '@mui/material';
const { Box } = require('@mui/system');
import paymentIcon from '../../../../assets/images/icons/payment.svg';

const Payment = () => {
    return (
        <Box sx={{ background: '#EEF3FC', borderRadius: '10px' }}>
            <Box display="flex" style={{ padding: '10px' }}>
                <img style={{ paddingRight: '10px' }} src={paymentIcon} alt="" />
                <Typography style={{ color: '#223E92' }}>Rp. 888.888.888</Typography>
            </Box>
        </Box>
    );
};

export default Payment;
