//router
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './mainRoutes';
import AuthenticationRoutes from './authenticationRoutes';
import NotFound from './notFound';
import Free from './freeLayout';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const page = [MainRoutes, AuthenticationRoutes, NotFound, Free];

    return useRoutes(page);
}
