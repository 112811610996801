// project imports

import { DATA_CUSTOMER, DETAIL_CUSTOMER, SEARCH_CUSTOMER } from 'store/actions/customerAction';

// action - state management

export const initialState = {
    customer_detail: {},
    data_customer: [],
    search: ''
};

// ==============================|| Customer REDUCER ||============================== //

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case DETAIL_CUSTOMER:
            return {
                ...state,
                customer_detail: action.payload
            };
        case DATA_CUSTOMER:
            return {
                ...state,
                data_customer: action.payload
            };
        case SEARCH_CUSTOMER:
            return {
                ...state,
                search: action.payload
            };

        default:
            return state;
    }
};

export default customerReducer;
