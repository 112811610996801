import { Box, Button, Typography } from '@mui/material';
import notFound from '../../assets/images/not-found.svg';
import { useNavigate } from 'react-router';

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Box>
                <img src={notFound} alt="" />
                <Typography align="center" mt={3} color="#32439E" fontWeight="bold" fontSize="36px">
                    404 Error: Page not found.
                </Typography>
                <Typography mt={2} style={{ maxWidth: '500px' }} align="center" color="#76777F">
                    Oops! It seems like the page you were trying to access doesn&apos;t exist. Please navigate back to the homepage.
                </Typography>
                <Box sx={{ textAlign: 'center' }} mt={3}>
                    <Button onClick={() => navigate('/')} variant="contained" color="secondary">
                        Back to home
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default NotFound;
