// project imports

import { SHOW_DETAIL, LOADING_SCREEN } from 'store/actions/pricingAction';

// action - state management

export const initialState = {
    show_detail: false,
    loading_screen: false
};

// ==============================|| DASHBOARD REDUCER ||============================== //

const pricingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_DETAIL:
            return {
                ...state,
                show_detail: action.payload
            };
        case LOADING_SCREEN:
            return {
                ...state,
                loading_screen: false
            };

        default:
            return state;
    }
};

export default pricingReducer;
