// project imports

import { DATA_LOCATION } from 'store/actions/locationAction';

// action - state management

export const initialState = {
    data_location: [],
    open: false
};

// ==============================|| Customer REDUCER ||============================== //

const locationReducer = (state = initialState, action) => {
    switch (action.type) {
        case DATA_LOCATION:
            return {
                ...state,
                data_location: action.payload
            };
        case 'OPEN':
            return {
                ...state,
                open: action.payload
            };
        default:
            return state;
    }
};

export default locationReducer;
