// project imports

import { LIST_NODE, NODE, NODE_TIER, UPDATE_NODE } from 'store/actions/nodeAction';

// action - state management

export const initialState = {
    list_node: [],
    node: [],
    node_tier: [],
    update_node: {
        update: false,
        tier: 0
    }
};

// ==============================|| Customer REDUCER ||============================== //

const nodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIST_NODE:
            return {
                ...state,
                list_node: action.payload
            };
        case NODE:
            return {
                ...state,
                node: action.payload
            };
        case NODE_TIER:
            return {
                ...state,
                node_tier: action.payload
            };
        case UPDATE_NODE:
            return {
                ...state,
                update_node: action.payload
            };

        default:
            return state;
    }
};

export default nodeReducer;
