// project imports
import { DATA_IPPOOL, DATA_IP_ADDRESS, IP_POOL_DETAIL } from 'store/actions/ipPoolActions';

// action - state management

export const initialState = {
    ip_pool_detail: {},
    data_ippool: [],
    data_ip_address: []
};

// ==============================|| Customer REDUCER ||============================== //

const ipPoolReducer = (state = initialState, action) => {
    switch (action.type) {
        case IP_POOL_DETAIL:
            return {
                ...state,
                ip_pool_detail: action.payload
            };
        case DATA_IPPOOL:
            return {
                ...state,
                data_ippool: action.payload
            };
        case DATA_IP_ADDRESS:
            return {
                ...state,
                data_ip_address: action.payload
            };

        default:
            return state;
    }
};

export default ipPoolReducer;
