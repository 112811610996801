// project imports

import { DATA_SETTING, SETTING } from 'store/actions/settingAction';

// action - state management

export const initialState = {
    setting_data: [],
    setting: []
};

// ==============================|| Customer REDUCER ||============================== //

const settingReducer = (state = initialState, action) => {
    switch (action.type) {
        case DATA_SETTING:
            return {
                ...state,
                setting_data: action.payload
            };
        case SETTING:
            return {
                ...state,
                setting: action.payload
            };

        default:
            return state;
    }
};

export default settingReducer;
