import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/breadcrumbs';
import Header from './header';
import Sidebar from './sidebar';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';

// assets
import { IconChevronRight } from '@tabler/icons';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LoadingScreen from 'ui-component/loadingScreen';
import AuthService from 'service/auth.service';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    background: '#F5F7F9',
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        marginTop: '60px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 70),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();

    const [loadingScreen, setLoadingScreen] = useState(false);

    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    const handleLogout = async (setLogoutLoading) => {
        setLogoutLoading(true);
        AuthService.logout().then(() => {
            setTimeout(() => {
                setLogoutLoading(false);
                window.location.href = '/login';
            }, 1000);
        });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    useEffect(() => {
        setLoadingScreen(true);
        if (!localStorage.getItem('user_info')) {
            navigate('/login');
        }
        setTimeout(() => {
            setLoadingScreen(false);
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {loadingScreen ? (
                <LoadingScreen />
            ) : (
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />

                    <AppBar
                        enableColorOnDark
                        position="fixed"
                        color="inherit"
                        elevation={0}
                        sx={{
                            bgcolor: theme.palette.background.default,
                            transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
                            height: '60px',
                            borderBottom: '1px solid #EEEE'
                        }}
                    >
                        <Toolbar>
                            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} handleLogout={handleLogout} />
                        </Toolbar>
                    </AppBar>

                    {/* drawer */}
                    <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} handleLogout={handleLogout} />

                    {/* main content */}
                    <Main theme={theme} open={leftDrawerOpened}>
                        {/* breadcrumb */}
                        <Box
                            onClick={handleLeftDrawerToggle}
                            sx={{
                                background: 'white',
                                border: '1px solid #EEEE',
                                width: '25px',
                                height: '25px',
                                borderRadius: '50%',
                                boxShadow: '0px 4px 10px rgb(50 67 158 / 5%)',
                                left: leftDrawerOpened ? '249px' : '54px',
                                top: '46px',
                                zIndex: 1200,
                                position: 'fixed',
                                cursor: 'pointer',
                                '&:hover': {
                                    background: theme.palette.primary.dark,
                                    color: theme.palette.primary.light
                                },
                                display: { xs: 'none', md: 'block' }
                            }}
                        >
                            {leftDrawerOpened ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightIcon />}
                        </Box>
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </Main>
                </Box>
            )}
        </>
    );
};

export default MainLayout;
