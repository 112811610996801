import { useState, useRef, useEffect } from 'react';

import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    CircularProgress,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Typography
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/mainCard';
import Transitions from 'ui-component/extended/transitions';

// assets
import logoutIcon from '../../../../assets/images/icons/logout.svg';
// eslint-disable-next-line no-unused-vars
import { IconLogout } from '@tabler/icons';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import Payment from '../payment';
import { useNavigate } from 'react-router';

// ==============================|| PROFILE MENU ||============================== //

// eslint-disable-next-line react/prop-types
const ProfileSection = ({ handleLogout }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const customization = useSelector((state) => state.customization);
    const user = JSON.parse(localStorage.getItem('user_info'));

    const [open, setOpen] = useState(false);
    const [logoutLoading, setLogoutLoading] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const prevOpen = useRef(open);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    // eslint-disable-next-line no-unused-vars
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    border: 'none',
                    backgroundColor: 'none',
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        sx={{
                            ...theme.typography.mediumAvatar,
                            background: '#223E92',
                            color: 'white',
                            // margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    >
                        {user?.name?.charAt(0).toUpperCase()}
                    </Avatar>
                }
                label={
                    <Box display="flex">
                        <Box>
                            <Typography fontWeight="500" style={{ fontSize: '14px', color: 'black' }}>
                                {user?.name}
                            </Typography>
                            <Typography style={{ fontSize: '12px', color: '#959595' }}> {user?.roles}</Typography>
                        </Box>
                        <Box paddingLeft={1} display="flex" justifyContent="center" alignItems="center">
                            <ExpandCircleDownOutlinedIcon style={{ color: '#1D243C', fontSize: '18px', display: 'none' }} />{' '}
                        </Box>
                    </Box>
                }
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box>
                                        <Box m={2} sx={{ display: { xs: 'block', sm: 'none' } }} paddingTop={2}>
                                            <Payment />
                                        </Box>
                                    </Box>

                                    <Box sx={{ pl: 1, pr: 1 }}>
                                        <List
                                            component="nav"
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 200,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                },
                                                '& .MuiListItemButton-root': {
                                                    mt: 0.5
                                                }
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                onClick={() => [navigate('/profile'), setOpen((prevOpen) => !prevOpen)]}
                                            >
                                                <ListItemIcon>
                                                    <AccountCircleIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">Profile</Typography>} />
                                            </ListItemButton>
                                            <Box mt={1} mb={1}>
                                                <Divider />
                                            </Box>
                                            <ListItemButton
                                                disabled={logoutLoading}
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                onClick={() => handleLogout(setLogoutLoading)}
                                            >
                                                {logoutLoading ? (
                                                    <>
                                                        <ListItemIcon>
                                                            <CircularProgress size={20} sx={{ color: '#000' }} />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography
                                                                    sx={{ position: 'relative', top: '-1px' }}
                                                                    fontFamily={'Manrope Medium'}
                                                                    color="error"
                                                                    variant="body2"
                                                                >
                                                                    Logging Out
                                                                </Typography>
                                                            }
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <ListItemIcon>
                                                            <img src={logoutIcon} alt="" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography
                                                                    sx={{ position: 'relative', top: '-1px' }}
                                                                    fontFamily={'Manrope Medium'}
                                                                    color="error"
                                                                    variant="body2"
                                                                >
                                                                    Logout
                                                                </Typography>
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
