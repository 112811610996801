// project imports

import { DATA_SYSTEM, LIST_TEMPLATE, OS } from 'store/actions/osAction';

// action - state management

export const initialState = {
    list_template: [],
    os: [],
    data_system: []
};

// ==============================|| Customer REDUCER ||============================== //

const osReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIST_TEMPLATE:
            return {
                ...state,
                list_template: action.payload
            };
        case OS:
            return {
                ...state,
                os: action.payload
            };
        case DATA_SYSTEM:
            return {
                ...state,
                data_system: action.payload
            };

        default:
            return state;
    }
};

export default osReducer;
