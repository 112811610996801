import NotFoundPage from '../views/notFound';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const NotFound = {
    path: '*',
    element: <NotFoundPage />
};

export default NotFound;
