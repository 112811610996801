// project imports

import { DATA_ADMIN } from 'store/actions/adminAction';

// action - state management

export const initialState = {
    data_admin: [],
    open: false
};

// ==============================|| DASHBOARD REDUCER ||============================== //

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case DATA_ADMIN:
            return {
                ...state,
                data_admin: action.payload
            };
        case 'OPEN':
            return {
                ...state,
                open: false
            };

        default:
            return state;
    }
};

export default adminReducer;
